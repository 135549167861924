import clsx from 'clsx';
import { Field, FormikProvider, useFormik } from 'formik';
import loadScript from 'load-script2';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import Cookies from 'js-cookie';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Button } from '../../../atoms/button';
import { CallUs } from '../../../atoms/call-us';
import { PBSLink } from '../../../atoms/link';
import { SidebarHeader } from '../../../atoms/sidebar-header';
import { TextInput } from '../../../atoms/text-input';

import { getCookie, sendTrackingData, sleep } from '../../../../utils';
import {
	validateEmail,
	validatePhoneNumber,
	validateString,
} from '../../../../utils/marketo';

import AccessLock from '../../../../assets/access-lock.svg';
import { useMarketo } from '../../../../contexts/marketo-context';
import { useFormValidation } from '../../../../hooks/form-validation';
import { useMarketoFormSubmit } from '../../../../hooks/marketo';
import { useCanSalesforceApi } from '../../../../hooks/salesforce/can-lookup';
import usePrivacyLink from '../../../../hooks/usePrivacyLink';
import { LOCALE_PATHS } from '../../../../utils/locale';
import { initializeUserTracking } from '../../../../utils/userTracking';
import { RadioButton } from '../../../atoms/radio-button';

const UnlockAccessForm = ({
	setConsentResponse,
	pageUrl,
	setModalOpen,
	locale: datoLocale,
	className,
	headerContent,
	subTextContent,
	setClearConsent,
	// eslint-disable-next-line react/prop-types
	webinar,
}) => {
	const MktoForms2Instances = useMarketo();
	const { executeRecaptcha } = useGoogleReCaptcha();
	const [technicalError, setTechnicalError] = useState(false);
	const [isClient, setIsClient] = useState('');
	const [referralCookie, setReferralCookie] = useState('');
	const [keywordCookie, setKeywordCookie] = useState('');
	const [mediumCookie, setMediumCookie] = useState('');
	const [campaignCookie, setCampaignCookie] = useState('');
	const [msclkidCookie, setMsclkidCookie] = useState('');
	const [msclkidLastCookie, setMsclkidLastCookie] = useState('');
	const [gclidCookie, setGclidCookie] = useState('');
	const [gclidLastCookie, setGclidLastCookie] = useState('');
	const [campaignLastCookie, setCampaignLastCookie] = useState('');
	const [contentLastCookie, setContentLastCookie] = useState('');
	const [mediumLastCookie, setMediumLastCookie] = useState('');
	const [sourceLastCookie, setSourceLastCookie] = useState('');
	const [termLastCookie, setTermLastCookie] = useState('');
	const [googleCookie, setGoogleCookie] = useState('');
	const [socialId, setSocialId] = useState('');
	const [canNumberMessage, setCanNumberMessage] = useState(null);
	const [canNumberType, setCanNumberType] = useState(null);
	const [loading, setLoading] = useState(false);
	const locale = LOCALE_PATHS[datoLocale];
	const { privacyLink } = usePrivacyLink(datoLocale);
	const { fetchCanSalesforce } = useCanSalesforceApi();
	const title = 'Access your content';

	let formId;
	switch (locale) {
		case 'ie':
			formId = webinar ? '6955' : '6793';
			break;

		case 'ca':
			formId = '5171';
			break;

		default:
			formId = '6916';
			break;
	}
	useEffect(() => {
		// eslint-disable-next-line compat/compat
		const urlObj = new URL(pageUrl);
		setSocialId(urlObj.searchParams.get('social_id'));
	}, [pageUrl]);

	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('arrange_a_callback', {
			form_name: title,
		});
	};

	const initialValidations = {
		FirstName: { isValid: true },
		LastName: { isValid: true },
		Email: { isValid: true },
		Phone: { isValid: true },
		Company: { isValid: true },
		Title: { isValid: true },
		isClient: { isValid: true },
		CanNumber: { isValid: true },
	};

	const firstNameRef = useRef(null);
	const lastNameRef = useRef(null);
	const companyRef = useRef(null);
	const titleRef = useRef(null);
	const emailRef = useRef(null);
	const phoneNumberRef = useRef(null);
	const isClientRef = useRef(null);
	const canNumberRef = useRef(null);

	const { validations, validateForm } = useFormValidation(initialValidations);

	useEffect(() => {
		if (isClient === 'yes') {
			if (!validations?.CanNumber?.isValid && canNumberRef?.current) {
				return canNumberRef.current.focus();
			}
		} else if (isClient === 'no') {
			if (!validations?.FirstName?.isValid && firstNameRef?.current) {
				return firstNameRef.current.focus();
			}

			if (!validations?.LastName.isValid && lastNameRef?.current) {
				return lastNameRef.current.focus();
			}

			if (!validations?.Email?.isValid && emailRef?.current) {
				return emailRef.current.focus();
			}

			if (!validations?.Phone?.isValid && phoneNumberRef?.current) {
				return phoneNumberRef.current.focus();
			}

			if (!validations?.Company?.isValid && companyRef?.current) {
				return companyRef.current.focus();
			}
			if (
				locale !== 'ie' &&
				!validations?.Title?.isValid &&
				titleRef?.current
			) {
				return titleRef.current.focus();
			}
		}
	}, [isClient, locale, validations]);

	const initialValues = {
		FirstName: '',
		LastName: '',
		Email: '',
		Phone: '',
		Company: '',
		Title: '',
		PersonSource: 'PBS GLU E-Learning',
		referalSource: referralCookie,
		keyword: keywordCookie,
		utm_medium: mediumCookie,
		utm_campaign: campaignCookie,
		MSCLKID__c: msclkidCookie,
		MSCLKID_last: msclkidLastCookie,
		GCLID__c: gclidCookie,
		gCLID_last: gclidLastCookie,
		utm_campaign_last__c: campaignLastCookie,
		utm_content_last__c: contentLastCookie,
		utm_medium_last__c: mediumLastCookie,
		utm_source_last__c: sourceLastCookie,
		utm_term_last__c: termLastCookie,
		Google_Cookie_ID__c: googleCookie,
		socialCampaignID: socialId,
	};

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		validateOnChange: false,
		validateOnBlur: false,
	});

	const initSaveUser = async ({ contact, eventType, loc }) => {
		if (loc === 'ca' || loc === 'au') return;
		try {
			await initializeUserTracking(contact, eventType, loc);
		} catch (error) {
			// Handle error if needed
			// console.error('Error initializing user tracking:', error);
		}
	};
	const submitHandler = async (event) => {
		setLoading(true);
		event.preventDefault();

		const { formEntries, isValid } = validateForm(event.currentTarget);
		if (!isValid) {
			setLoading(false);
			return;
		}

		if (isClient === 'yes' && formEntries.CanNumber !== '' && !webinar) {
			// clear any previous messages
			setCanNumberMessage('Validating...');
			setCanNumberType(null);
			// check if can number is a valid email
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			if (
				formEntries.CanNumber.includes('@') &&
				emailRegex.test(formEntries.CanNumber)
			) {
				await initSaveUser({
					contact: formEntries.CanNumber,
					eventType: 'Client-Email',
					loc: locale,
				});
				setTimeout(() => {
					setCanNumberMessage('Your Email is valid');
					setCanNumberType('success');
					setLoading(false);
				}, 1400);
				// track the click
				handleClickTracking();
				setTimeout(() => {
					setConsentResponse(true);
					setClearConsent(true);
					Cookies.set('elearningContent', '1', { expires: 30 });
					Cookies.set('start__c', '1', { expires: 30 });
				}, 2500);

				return;
			}
			if (!executeRecaptcha) {
				setCanNumberMessage('Please refresh the page and try again');
				setCanNumberType('error');
				setLoading(false);
				return;
			}
			const canPayload = {
				CAN: formEntries.CanNumber,
				locale,
			};

			const response = await fetchCanSalesforce(
				executeRecaptcha,
				canPayload,
				'POST'
			);
			if (response.status === 200) {
				if (response.body && response.body.identifier === 'true') {
					await initSaveUser({
						contact: formEntries.CanNumber,
						eventType: 'Client-CAN',
						loc: locale,
					});
					setCanNumberMessage('Your Account number/Email is valid');
					setCanNumberType('success');
					handleClickTracking();
					setTimeout(() => {
						setCanNumberMessage('Accessing...');
						setCanNumberType('success');
					}, 1500);
					setTimeout(() => {
						setConsentResponse(true);
						setClearConsent(true);
						Cookies.set('elearningContent', '1', { expires: 30 });
						Cookies.set('start__c', '1', { expires: 30 });
						if (!webinar) {
							// Access given
							setModalOpen(false);
						}
					}, 2500);
				} else {
					setCanNumberMessage('Invalid account number or email');
					setCanNumberType('error');
				}
			}
			setLoading(false);
			return;
		}

		const payloadData = {
			...initialValues,
			FirstName: formEntries.FirstName,
			LastName: formEntries.LastName,
			Phone: formEntries.Phone,
			Email: formEntries.Email,
			Company: formEntries.Company,
			Title: formEntries.Title,
			PersonSource: webinar
				? 'Website - PBS Webinar'
				: 'Website - PBS GLU E-Learning',
			MSCLKID: getCookie('msclkid'),
			MSCLKID_last: getCookie('msclkid_last'),
			GCLID: getCookie('gclid'),
			GCLID_last: getCookie('gclid_last'),
		};

		await sleep(500);
		useMarketoFormSubmit(payloadData, formId, MktoForms2Instances)
			.then(async () => {
				formik.setStatus('submitted');
				await initSaveUser({
					contact: formEntries.Email,
					eventType: 'Prospect',
					loc: locale,
				});
				Cookies.set(
					webinar ? 'webinarContent' : 'elearningContent',
					'1',
					{ expires: webinar ? 7 : 1 }
				);
				if (!webinar) {
					Cookies.set('start__c', '1', { expires: 7 });
				}
				setConsentResponse(true);
				setClearConsent(true);
				if (!webinar) {
					// Access given
					setModalOpen(false);
				}
				setLoading(false);
			})
			.catch(() => {
				formik.setStatus('error');
				setTechnicalError(true);
			})
			.finally(() => {
				handleClickTracking();
				setLoading(false);
			});
	};

	useEffect(() => {
		if (!formId) return; // Ensure formId is set before proceeding

		const loadForm = () => {
			const scriptUrl =
				locale === 'ca'
					? 'https://app-ab31.marketo.com/js/forms2/js/forms2.min.js'
					: 'https://app-lon04.marketo.com/js/forms2/js/forms2.min.js';

			const formUrl =
				locale === 'ca'
					? 'https://app-ab31.marketo.com'
					: 'https://app-lon04.marketo.com';

			const formIdValue = locale === 'ca' ? '544-LUT-177' : '023-IMK-845';

			loadScript(scriptUrl)
				.then(() => {
					MktoForms2Instances.get(formUrl).loadForm(
						formUrl,
						formIdValue,
						formId
					);
				})
				.catch(() => {
					// Handle error if needed
				});
		};

		if (window.requestIdleCallback) {
			window.requestIdleCallback(loadForm);
		} else {
			setTimeout(loadForm);
		}

		setReferralCookie(getCookie('utm_source'));
		setKeywordCookie(getCookie('utm_term'));
		setMediumCookie(getCookie('utm_medium'));
		setCampaignCookie(getCookie('utm_campaign'));
		setMsclkidCookie(getCookie('msclkid'));
		setMsclkidLastCookie(getCookie('msclkid_last'));
		setGclidCookie(getCookie('gclid'));
		setGclidLastCookie(getCookie('gclid_last'));
		setCampaignLastCookie(getCookie('utm_campaign_last'));
		setContentLastCookie(getCookie('utm_content_last'));
		setMediumLastCookie(getCookie('utm_medium_last'));
		setSourceLastCookie(getCookie('utm_source_last'));
		setTermLastCookie(getCookie('utm_term_last'));
		setGoogleCookie(getCookie('Google_Cookie_ID__c'));
	}, [locale, MktoForms2Instances, formId]);

	let btnText;
	switch (isClient) {
		case 'yes':
			btnText = 'Verify Details';
			break;
		case 'no':
			btnText = title;
			break;
		default:
			btnText = webinar ? title : 'Please select an option above';
			break;
	}
	return technicalError ? (
		<div className="px-4 pb-10 bg-white">
			<SidebarHeader
				heading="We've run into a technical error with your submission"
				text="Don't worry though, it just means you need to give us a call instead and we'll be happy to help."
				ariaLive="assertive"
			/>
			<CallUs className="p-4" />
		</div>
	) : (
		<FormikProvider value={formik}>
			<form
				data-formid={formId}
				data-forminstance="one"
				onSubmit={submitHandler}
				className={clsx(
					'flex flex-col justify-between w-full mx-auto overflow-hidden rounded-sm grow',
					className
				)}
			>
				<div className="flex flex-col p-4 lg:p-8 lg:flex-row bg-brand-blue-400">
					<div className="w-full text-white lg:w-3/4 lg:pr-10">
						<h2 className="mb-3 text-2xl lg:text-4xl font-lexend-bold">
							{webinar ? title : headerContent}
						</h2>
						<p className=" font-notosans-regular">
							{subTextContent}
						</p>
					</div>
					<div className="justify-center hidden w-1/4 m-auto lg:flex lg:pt-0">
						<img
							src={AccessLock}
							alt="Unlock Content"
							className="w-1/8"
						/>
					</div>
				</div>
				<div className="flex flex-col bg-white grow">
					{!webinar ? (
						<>
							<fieldset
								className={clsx(
									' mt-5 flex flex-col px-4  bg-white lg:pt-6 lg:px-8 grow',
									isClient === 'yes' ? 'pt-5' : 'py-5'
								)}
							>
								<legend className=" text-brand-blue-400 text-base-f font-lexend-medium">
									Are you a Peninsula client?
								</legend>

								<div className="flex flex-wrap gap-2 ">
									<RadioButton
										defaultChecked={isClient === 'no'}
										labelText="No"
										id="enquireNo"
										name="isClient"
										value={isClient}
										ref={isClientRef}
										onChange={() => setIsClient('no')}
									/>

									<RadioButton
										defaultChecked={isClient === 'yes'}
										labelText="Yes"
										id="enquireYes"
										name="isClient"
										ref={isClientRef}
										value={isClient}
										onChange={() => setIsClient('yes')}
									/>
								</div>
							</fieldset>
							{isClient === 'yes' && !webinar ? (
								<fieldset className="flex flex-col px-4 pt-5 bg-white lg:pt-6 lg:px-8 grow">
									<Field
										name="CanNumber"
										validate={validateString}
									>
										{({ field }) => (
											<div className="w-full ">
												<label
													htmlFor="CanNumber"
													className="text-brand-blue-400 lg:text-base-f font-lexend-medium"
												>
													Your Account
													Number/Email&#42;
												</label>
												<TextInput
													id="CanNumber"
													name="CanNumber"
													aria-required="true"
													className="w-full mb-3 wrapper-small"
													ref={canNumberRef}
													showValidation={
														!validations?.CanNumber
															?.isValid &&
														!canNumberRef?.current
															?.value
													}
													validationMessage={
														!validations?.CanNumber
															?.isValid
															? 'Please enter your Account number or email'
															: null
													}
													{...field}
													space={false}
													placeholder="Account Number or Email"
													validateOnBlur
												/>
												<div className="flex justify-start">
													{canNumberMessage && (
														<p
															className={clsx(
																canNumberType ===
																	'error'
																	? 'text-brand-red-500 text-sm'
																	: 'text-success-500 text-sm'
															)}
														>
															{canNumberMessage}
														</p>
													)}
												</div>
											</div>
										)}
									</Field>
								</fieldset>
							) : null}
						</>
					) : null}
					{isClient === 'no' || webinar ? (
						<fieldset className="flex flex-col px-4 pt-5 bg-white lg:pt-10 lg:px-8 grow">
							<legend className="sr-only">
								Enter your personal details
							</legend>
							<div className="flex flex-col lg:flex-row lg:gap-6">
								<Field
									name="FirstName"
									validate={validateString}
								>
									{({ field }) => (
										<div className="w-full lg:w-1/2">
											<label
												htmlFor="FirstName"
												className="text-brand-blue-400 font-lexend-medium"
											>
												First name&#42;
											</label>
											<TextInput
												id="FirstName"
												name="FirstName"
												aria-required="true"
												className="w-full mb-3 wrapper-small"
												ref={firstNameRef}
												showValidation={
													!validations?.FirstName
														?.isValid &&
													!firstNameRef?.current
														?.value
												}
												validationMessage={
													!validations?.FirstName
														?.isValid
														? 'Please enter your first name'
														: null
												}
												{...field}
												space={false}
												placeholder={`${
													locale === 'ie'
														? 'Sean'
														: 'Jane'
												}`}
											/>
										</div>
									)}
								</Field>

								<Field
									name="LastName"
									validate={validateString}
								>
									{({ field }) => (
										<div className="w-full lg:w-1/2">
											<label
												htmlFor="LastName"
												className="text-brand-blue-400 font-lexend-medium"
											>
												Last name&#42;
											</label>
											<TextInput
												id="LastName"
												name="LastName"
												aria-required="true"
												className="w-full mb-3 wrapper-small"
												ref={lastNameRef}
												showValidation={
													!validations?.LastName
														?.isValid &&
													!lastNameRef?.current?.value
												}
												validationMessage={
													!validations?.LastName
														?.isValid
														? 'Please enter your last name'
														: null
												}
												{...field}
												space={false}
												placeholder={`${
													locale === 'ie'
														? 'Murphy'
														: 'Smith'
												}`}
											/>
										</div>
									)}
								</Field>
							</div>
							{locale !== 'ie' && (
								<Field name="Title" validate={validateString}>
									{({ field }) => (
										<div>
											<label
												htmlFor="Title"
												className="text-brand-blue-400 font-lexend-medium"
											>
												Job Title&#42;
											</label>
											<TextInput
												id="Title"
												name="Title"
												aria-required="true"
												className="w-full mb-3 wrapper-small"
												ref={titleRef}
												showValidation={
													!validations?.Title
														?.isValid &&
													!titleRef?.current?.value
												}
												validationMessage={
													!validations?.Title?.isValid
														? 'Please enter your job title'
														: null
												}
												{...field}
												space={false}
												placeholder="Job Title"
											/>
										</div>
									)}
								</Field>
							)}
							<Field name="Company" validate={validateString}>
								{({ field }) => (
									<div>
										<label
											htmlFor="Company"
											className="text-brand-blue-400 font-lexend-medium"
										>
											Company&#42;
										</label>
										<TextInput
											id="Company"
											name="Company"
											aria-required="true"
											className="w-full mb-3 wrapper-small"
											ref={companyRef}
											showValidation={
												!validations?.Company
													?.isValid &&
												!companyRef?.current?.value
											}
											validationMessage={
												!validations?.Company?.isValid
													? 'Please enter your company'
													: null
											}
											{...field}
											space={false}
											placeholder="Company LTD"
										/>
									</div>
								)}
							</Field>

							<Field name="Email" validate={validateEmail}>
								{({ field }) => (
									<div>
										<label
											htmlFor="Email"
											className="text-brand-blue-400 font-lexend-medium"
										>
											Email&#42;
										</label>
										<TextInput
											id="Email"
											type="email"
											name="Email"
											aria-required="true"
											className="w-full mb-3 wrapper-small"
											ref={emailRef}
											showValidation={
												!validations?.Email?.isValid &&
												!emailRef?.current?.value
											}
											validationMessage="Please enter your email address"
											{...field}
											space={false}
											placeholder={`${
												locale === 'ie'
													? 'sean.murphy@gmail.com'
													: 'jane.smith@gmail.com'
											}`}
										/>
									</div>
								)}
							</Field>

							<Field name="Phone" validate={validatePhoneNumber}>
								{({ field }) => (
									<div>
										<label
											htmlFor="Phone"
											className="text-brand-blue-400 font-lexend-medium"
										>
											Phone&#42;
										</label>
										<TextInput
											id="Phone"
											name="Phone"
											type="tel"
											title="Please enter numbers only."
											pattern="[0-9]{10,14}"
											aria-required="true"
											className="w-full mb-3 wrapper-small"
											ref={phoneNumberRef}
											showValidation={
												!validations?.Phone?.isValid &&
												!phoneNumberRef?.current?.value
											}
											validationMessage="Please enter a phone number"
											{...field}
											space={false}
											placeholder={
												// eslint-disable-next-line no-nested-ternary
												locale === 'ie'
													? '087 1234 567'
													: locale === 'ca'
													? '1800 555 4321'
													: '07997 210 007'
											}
										/>
									</div>
								)}
							</Field>
						</fieldset>
					) : null}
				</div>

				<div className="flex flex-col px-4 pt-3 pb-6 bg-white lg:items-center item-start lg:px-8 lg:flex-row lg:justify-between">
					<div className="wrapper-small">
						<Button
							type="submit"
							disabled={
								(isClient === '' && !webinar) ||
								formik.isSubmitting
							}
							className={clsx(
								formik.isSubmitting
									? 'justify-center loading'
									: null
							)}
						>
							<span
								className={clsx(
									'text-sm',
									formik.isSubmitting ? 'invisible' : null
								)}
							>
								{loading ? 'please wait...' : btnText}
							</span>
							{formik.isSubmitting ? (
								<span className="sr-only" aria-live="assertive">
									Submitting your details, please wait...
								</span>
							) : null}
						</Button>
					</div>
					<p className="px-1 pt-4 text-sm bg-white lg:px-4 lg:text-center text-start lg:pt-0">
						View our{' '}
						<PBSLink
							to={privacyLink}
							variant="Link"
							size="Small"
							className="!text-sm"
						>
							privacy notice.
						</PBSLink>
					</p>
				</div>
			</form>
		</FormikProvider>
	);
};

UnlockAccessForm.defaultProps = {
	className: '',
	headerContent: 'Unlock your free e-learning content',
	subTextContent: 'Fill out the details below to gain access',
};

UnlockAccessForm.propTypes = {
	setConsentResponse: PropTypes.func.isRequired,
	setClearConsent: PropTypes.func.isRequired,
	pageUrl: PropTypes.string.isRequired,
	setModalOpen: PropTypes.func.isRequired,
	locale: PropTypes.string.isRequired,
	className: PropTypes.string,
	headerContent: PropTypes.string,
	subTextContent: PropTypes.string,
};

export { UnlockAccessForm };
