import clsx from 'clsx';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { sendTrackingData } from '../../../../utils';
import { buildLink } from '../../../../utils/locale';
import { PBSLink } from '../../../atoms/link';

const HomeReferralCards = ({
	// card,
	heroHeader,
	headingStyles,
	titleText,
	locale,
	slug,
}) => {
	const title = 'Recommend a business';
	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'navigation',
			click_action: 'navigate',
			click_type: 'link',
			click_text: title,
			click_title: title,
		});
	};
	const handleClick = () => {
		const link = buildLink(locale, slug);
		handleClickTracking();
		navigate(link);
	};

	const Title = 'h1';
	return (
		<div
			className={clsx(
				'w-full bg-brand-pale-500 pt-2'
				// !slug && !isHomePage && "mt-20",
			)}
		>
			<section className="pb-10 max-w-m-screen mx-auto flex flex-col items-center justify-center flex-grow h-auto mt-16">
				<Title
					id={heroHeader}
					className={clsx(
						'!leading-[1.3] font-lexend-regular',
						'text-2xl md:text-5xl xl:text-6xl',
						headingStyles
					)}
				>
					{titleText}
				</Title>
				<div className="mt-16 grid md:grid-cols-2 grid-cols-1 2xl:gap-4 w-full mb-3 ">
					<div className="flex flex-col">
						{slug && (
							<>
								<div className="flex flex-col gap-6 w-full">
									<h2 className="lg:text-5xl md:text-3xl text-2xl text-balance leading-5 ">
										A Free Two-Night Stay and £500 Pre-Paid
										Card
									</h2>
									<p className="2xl:text-2xl text-lg font-lexend-light text-balance text-brand-red-400">
										Recommend Peninsula and discover your
										unlimited earning potential.
									</p>
								</div>
								<div className="flex justify-start mt-7">
									<PBSLink
										to="/refer-a-business/"
										onClick={handleClick}
									>
										Find out more
									</PBSLink>
								</div>
							</>
						)}
					</div>
					<div className="h-[100%] glu2xl:w-[70%] glulgx:w-[60%] glumd:w-full glulgx:ml-20 glu2xl:ml-32 quality-100 items-center justify-center hidden md:flex">
						<img
							loading="lazy"
							src="https://www.datocms-assets.com/64336/1744015056-heaning-reward-card-2.gif"
							alt="Refer a business"
							className="w-full h-full"
							style={{
								width: '100%',
								height: '100%',
							}}
						/>
					</div>
				</div>
			</section>
		</div>
	);
};
HomeReferralCards.propTypes = {
	locale: PropTypes.string.isRequired,
	slug: PropTypes.string.isRequired,
	// card: PropTypes.string.isRequired,
	heroHeader: PropTypes.string.isRequired,
	headingStyles: PropTypes.isRequired,
	titleText: PropTypes.string.isRequired,
};

export { HomeReferralCards };

